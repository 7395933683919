import React from 'react';
import {
  Barcode,
  Calendar,
  CertificateCheck,
  Chat,
  ChoroplethMap,
  CicsProgram,
  Currency,
  Dashboard,
  DataTable,
  DocumentUnknown,
  Education,
  Enterprise,
  Forum,
  Help,
  Home,
  QrCode,
  Settings,
  Task,
  Time,
  UserActivity,
  UserMultiple,
  UserProfile,
} from '@carbon/icons-react';
import {
  CommentAlert,
  Dumper,
  Loader,
  ProjectHat,
  TimeReport,
} from '@ditioas/web-ui';

const getLinkIcon = (name: string) => {
  switch (name) {
    case 'overview':
      return <Home />;
    case 'hours':
      return <Calendar />;
    case 'my-hours':
      return <Time />;
    case 'reports':
      return <TimeReport />;
    case 'payroll':
      return <Currency />;
    case 'alerts':
      return <CommentAlert />;
    case 'projects':
      return <ProjectHat />;
    case 'machines':
      return <Loader />;
    case 'info-center':
      return <Chat />;
    case 'crew-list':
      return <UserProfile />;
    case 'feed':
      return <Forum />;
    case 'map':
      return <ChoroplethMap />;
    case 'items':
      return <Barcode />;
    case 'mass-haul':
      return <Dumper />;
    case 'checklists':
      return <Task />;
    case 'es-training':
      return <Education />;
    case 'planning':
      return <DataTable />;
    case 'certificates':
      return <CertificateCheck />;
    case 'help-center':
      return <Help />;
    case 'users':
      return <UserMultiple />;
    case 'companies':
      return <Enterprise />;
    case 'dashboard':
      return <Dashboard />;
    case 'sysop':
      return <CicsProgram />;
    case 'settings':
      return <Settings />;
    case 'inactive-users':
      return <UserActivity />;
    case 'searchForUser':
      return <QrCode />;
    default:
      return <DocumentUnknown />;
  }
};

export default getLinkIcon;
